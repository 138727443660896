@import "../../../variables.scss";

.to-top-button {
    position: fixed;
    right: 10px;
    bottom: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 30px;
    border-radius: 4px;
    background-color: $color-main;
    z-index: 999;
    transition: all 0.3s;

    &:hover {
        background-color: $color-main-saturated;
    }

    button {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        cursor: pointer;

        img {
            width: 28px;
            height: auto;
            filter: brightness(0) invert(1);
            transform: rotate(180deg);
        }
    }
}
