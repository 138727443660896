@import "variables.scss";

.assessor-table-wrapper {
    table {
        border-collapse: initial;
        border-spacing: 0 8px;
        width: 100%;
        font-size: 12px;
        color: $color-black;

        thead tr th {
            background-color: $color-main;
            color: $color-white;
            font-weight: 700;
        }

        tr {
            :is(th, td):nth-child(1) {
                width: 10%;
            }

            :is(th, td):nth-child(2) {
                width: 20%;
                text-align: center;
            }

            :is(th, td):nth-child(3) {
                width: 20%;
                text-align: center;
            }

            :is(th, td):nth-child(4) {
                width: 20%;
                text-align: center;
                @media (max-width: $container-md) {
                    display: none;
                }
            }

            :is(th, td):nth-child(5) {
                width: 20%;
                text-align: center;
                @media (max-width: $container-md) {
                    display: none;
                }
            }
        }

        tr {
            

            > * {
                vertical-align: middle;
            }
        }

        th,
        td {
            padding: 16px 12px;
            border-color: #ebebeb;
            border-style: solid;
            border-width: 1px 0 1px 0;
        }

        :is(th, td):first-child {
            border-width: 1px 0 1px 1px;
            border-radius: $border-radius 0 0 $border-radius;
        }

        :is(th, td):last-child {
            border-width: 1px 1px 1px 0;
            border-radius: 0 $border-radius $border-radius 0;
        }
    }
}
