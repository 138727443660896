@import "variables.scss";

.application__assessor-assessment-title {
    margin-bottom: 20px;
    font-size: 16px;
    font-weight: 600;
}

.application__assessor-assessment-alert {
    padding: 12px;
    margin: 0 0 12px 0;
    border: 1px solid red;
    border-radius: 6px;
    background-color: #ffd7d7;
    font-size: 14px;

    span {
        font-weight: 600;
    }
}

.application__assessor-assessment-summary {
    table {
        border-collapse: separate;
        border-spacing: 0 8px;
        font-size: 14px;

        tr {
            &:nth-last-child(2) {
                background-color: $color-main-pale;
            }

            &:last-child {
                background-color: $color-main-pale-hover;
                font-weight: 600;
            }

            :is(th, td) {
                vertical-align: middle;
                padding: 8px;

                &:first-child {
                    border-width: 1px 0 1px 1px;
                    border-radius: 6px 0 0 6px;
                }

                &:last-child {
                    width: 100px;
                    border-width: 1px 1px 1px 0;
                    border-radius: 0 6px 6px 0;
                    text-align: center;
                }
            }

            th {
                border: 1px solid $color-border;
                background-color: $color-main;
                font-weight: 600;
                color: #ffffff;
            }

            td {
                border: 1px solid $color-border;
            }
        }
    }
}
