@import "../../../variables.scss";

.application__assessments-summary {
    display: flex;
    justify-content: center;

    div {
        flex: 0 0 200px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        h4 {
            margin: 0 0 12px 0;
            font-size: 14px;
        }

        p {
            padding: 12px;
            border-radius: 6px;
            background-color: $color-main-pale;
            font-size: 32px;
            font-weight: 700;
            color: $color-main;
        }
    }
}
