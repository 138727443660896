@import "variables.scss";

.application-assessor-adding {
    &__title {
        margin: 0 0 12px 0;
        font-size: 14px;
        font-weight: 600;
        color: $color-main;
    }

    &__select {
        display: flex;
        flex-direction: column;
        gap: 12px;
    }
}
