.application-contacts {
    margin-bottom: 24px;
    padding: 24px;
    border-radius: 10px;
    background: #ffffff;
    box-shadow: 4px 4px 8px #f8f8f8;
    overflow: hidden;

    &__title {
        margin: 0 0 20px 0;
        font-size: 16px;
        font-weight: 600;
    }

    p {
        display: flex;
        align-items: center;
        word-break: break-all;
        text-transform: lowercase;
        font-size: 14px;
    }
    img {
        padding-right: 6px;
        height: 22px;
        width: auto;
    }
}
