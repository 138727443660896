@import "variables.scss";

.application__field-criteria {
    h4 {
        margin: 12px 0 12px 0;
        font-size: 18px;
        font-weight: 600;
        color: $color-main;
    }

    &-button {
        padding: 4px 12px;
        border: 1px solid $color-main;
        border-radius: calc(2 * $border-radius);
        font-size: 12px;
        color: $color-main;
    }

    table {
        width: 100%;
        border-collapse: separate;
        border-spacing: 0 8px;
        font-size: 14px;
        color: $color-black;

        tr {
            &:nth-child(2n + 1) {
                background-color: $color-additional-pale;
            }

            &:nth-child(2n) {
                background-color: $color-white;
            }
        }

        td {
            vertical-align: middle;
            padding: 16px 12px;
            border-color: #ebebeb;
            border-style: solid;
            border-width: 1px 0 1px 0;

            &:first-child {
                min-width: 60px;
                text-align: center;
                font-weight: 600;
            }
        }

        :is(th, td):first-child {
            border-width: 1px 0 1px 1px;
            border-radius: $border-radius 0 0 $border-radius;
        }

        :is(th, td):last-child {
            border-width: 1px 1px 1px 0;
            border-radius: 0 $border-radius $border-radius 0;
        }
    }
}
