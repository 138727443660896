@import "variables";

.search-select {
    position: relative;

    &-input {
        position: relative;

        &-label {
            position: absolute;
            left: 12px;
            top: 8px;
            font-size: 14px;
            font-weight: 600;
            color: $color-main;
            transition: all 0.2s;
            user-select: none;

            &-required {
                color: #ff0000;
            }
        }

        > input {
            width: 100%;
            height: 40px;
            padding: 36px 12px 20px 12px;
            border-radius: 8px;
            border: 1px solid #e5e7eb;
            background: #fff;
            transition: border 0.3s;
            margin-bottom: 4px;
            font-size: 14px;

            &[data-disabled="true"] {
                background-color: #f1f1f1;
                cursor: not-allowed;
            }

            &::placeholder {
                color: #000000;
            }

            &:focus {
                outline: none;
                background-color: #f9f9f9;
            }

            &:focus::placeholder {
                color: #c6c6c6;
            }
        }
    }

    &-options {
        position: absolute;
        top: 100%;
        width: 100%;
        max-height: 200px;
        border-radius: 8px;
        background-color: #ffffff;
        overflow-y: scroll;
        overflow-x: hidden;
        overscroll-behavior: contain;
        box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
        z-index: 1;

        &::-webkit-scrollbar {
            width: 4px;
        }

        &::-webkit-scrollbar-track {
            background: transparent;
        }

        &::-webkit-scrollbar-thumb {
            background: #888;
            border-radius: 10px;
        }

        &::-webkit-scrollbar-thumb:hover {
            background: #555;
        }

        > div {
            padding: 8px 4px;
            font-size: 14px;
            text-align: left;
            cursor: pointer;
            transition: all 0.3s;

            &:hover {
                background-color: $color-main-pale;
            }
        }
    }
}
