@import "variables.scss";

.assessment-criteria {
    padding: 20px;
    margin-bottom: 12px;

    &-radio-control {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        gap: 4px;

        & > label {
            position: relative;
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 8px 24px;
            border: 1px solid $color-main;
            border-radius: $border-radius;
            background-color: #ffffff;
            font-size: 12px;
            cursor: pointer;
            transition: all 0.3;
            user-select: none;

            &[data-disabled="true"] {
                border: 1px solid $color-border;
                color: $color-border;
            }

            &[data-checked="true"] {
                background-color: $color-main;
                color: $color-white;
            }
        }

        & input {
            opacity: 0;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            cursor: pointer;
        }
    }
}
