.criteria {
    position: relative;
    min-height: 750px;
    margin-bottom: 60px;
}

@media (max-width: 767px) {
    .criteria__background {
        margin-bottom: 60px;
    }
}

.criteria__wrapper {
    display: flex;
    align-items: center;
    gap: 0 40px;
}

@media (max-width: 767px) {
    .criteria__wrapper {
        flex-direction: column;
    }
}

.criteria__content {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 50%;
    max-width: 473px;
}

@media (max-width: 767px) {
    .criteria__content {
        width: 100%;
        max-width: unset;
    }
}

.criteria__title {
    position: relative;
    max-width: 374px;
    margin: 0 0 100px 0;
    padding: 0;
    font-size: 40px;
    font-weight: 700;
    line-height: 130%;
    color: #354596;
    text-transform: uppercase;
}

@media (max-width: 767px) {
    .criteria__title {
        max-width: 320px;

        margin: 0 0 35px 0;
        font-size: 32px;
    }
}

.criteria__arrow {
    display: block;
    position: absolute;
    left: 64%;
    top: 87%;
    width: 176px;
}

@media (max-width: 767px) {
    .criteria__arrow {
        display: none;
    }
}

.criteria__arrow--mobile {
    position: absolute;

    display: none;
    width: 68px;
    top: 87%;
    left: 70%;
}

@media (max-width: 767px) {
    .criteria__arrow--mobile {
        display: block;
    }
}

.criteria__text {
    margin: 0 0 24px 0;
    padding: 0;
    font-size: 16px;
    font-weight: 400;
    line-height: 150%;
    color: #041e42;
}

.criteria__text--last {
    margin: 0;
}

.criteria__text span {
    font-size: 16px;
    font-weight: 700;
    line-height: 150%;
    color: #5ca0f5;
}

.criteria__list-decription {
    padding: 0 0 4px 0;
    color: #041e42;
}

.criteria__list {
    margin: 0 0 24px 0;
    font-size: 16px;
    font-weight: 400;
    line-height: 150%;
    color: #041e42;
}

.criteria__list li {
    padding: 0 0 4px 18px;
    font-size: 16px;
    font-weight: 400;
    line-height: 150%;
    color: #041e42;
}

.criteria__list li::before {
    background-color: #041e42;
}

.criteria__link {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    padding: 15px 70px;
    margin: 60px 0 0 0;
    border-radius: 40px;
    border: 2px solid #354596;
    text-align: center;

    font-size: 13px;
    font-weight: 700;
    line-height: 150%;
    color: #354596;

    text-transform: uppercase;
}

@media (max-width: 767px) {
    .criteria__link {
        align-self: center;
    }
}

.criteria__background {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 50%;
}

@media (max-width: 767px) {
    .criteria__background {
        width: 100%;
    }
}

@media (max-width: 767px) {
    .criteria__background-image {
        display: none;
    }
}

.criteria__background-image {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    width: 50vw;
    max-width: 700px;
}

@media (max-width: 767px) {
    .criteria__background-image {
        display: none;
    }
}

.criteria__background-image--mobile {
    display: none;
}

@media (max-width: 767px) {
    .criteria__background-image--mobile {
        display: block;
        width: 100%;
    }
}
