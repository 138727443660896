@import "variables.scss";

.file-container {
    width: 100%;
    border-radius: 10px;
    overflow: hidden;
}

.file-container-image {
    position: relative;
    box-shadow: 4px 4px 8px #f8f8f8;
}

.file-container-image > img {
    width: 100%;
    max-height: 300px;
    object-fit: cover;
}

.file-container-image > a {
    position: absolute;
    right: 8px;
    bottom: 8px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    padding: 2px;
    border-radius: 5px;
    background-color: #00000088;
}

.file-container-image > a > img {
    filter: invert(1);
}

.file-container-file {
    a {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 8px;
        padding: 12px 10px;
        border: 1px solid #ebebeb;
        border-radius: 10px;
        background-color: #ffffff;
        font-size: 14px;
        color: $color-black;
        text-decoration: none;
        text-align: center;
        transition: all 0.3s;

        &:hover {
            background-color: $color-main-pale;
        }

        img {
            width: 30px;
            height: auto;
        }

        span {
            flex-grow: 1;
            display: inline-flex;
            justify-content: center;
            align-items: center;
        }
    }
}
