@import "variables.scss";

.applications-assessors-table {
    border-collapse: initial;
    border-spacing: 0 8px;
    width: 100%;
    font-size: 12px;
    color: $color-black;

    thead tr th {
        background-color: $color-main;
        color: $color-white;
        font-weight: 700;
    }

    tr {
        box-shadow: $box-shadow;
    }

    th,
    td {
        padding: 16px 12px;
        border-color: #ebebeb;
        border-style: solid;
        border-width: 1px 0 1px 0;
    }

    :is(th, td):first-child {
        border-width: 1px 0 1px 1px;
        border-radius: $border-radius 0 0 $border-radius;
    }

    :is(th, td):last-child {
        border-width: 1px 1px 1px 0;
        border-radius: 0 $border-radius $border-radius 0;
    }
}

.applications-assessors-table {
    &__assessors-td {
        > span {
            display: flex;
            flex-direction: column;
            flex-wrap: wrap;
            gap: 4px;

            > a {
                display: inline-flex;
                width: fit-content;
                padding: 2px 6px;
                border-radius: 12px;
                background-color: $color-main-pale;
                font-size: 12px;
                color: $color-black;
                transition: all 0.3s;

                &:hover {
                    background-color: $color-main-pale-hover;
                }
            }
        }
    }
}
