.application-text-block {
    margin-bottom: 32px;

    &__title {
        margin-bottom: 20px;
        font-size: 16px;
        font-weight: 600;
    }

    &__tip {
        margin-bottom: 16px;
        font-size: 12px;
        font-style: italic;
    }

    &__text {
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        p {
            margin-bottom: 0.5rem;
            font-size: 14px;
            color: #181818;
            overflow-wrap: anywhere;
        }
    }
}

.application-text-btn {
    align-self: flex-end;
    border: 0;
    background-color: transparent;
    font-size: 14px;
    font-weight: 500;
    color: #354596;
    transition: opacity 0.3s;
}

.application-text-btn:hover {
    opacity: 0.5;
}
