@import "variables.scss";

.applications-statistics__wrapper {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 18px;
}

.applications-statistics__widget {
    flex: 1 1 120px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 4px;
    height: 120px;
    width: 140px;
    padding: 20px;
    border-radius: $border-radius;
    background-color: $color-main;
    color: $color-white;

    h3 {
        margin: 0 0 6px 0;
        text-align: center;
        font-size: 16px;
    }

    div {
        text-align: center;
        font-size: 24px;
        font-weight: 200;
        color: $color-white;
        font-weight: 600;

        span {
            font-size: 12px;
            font-weight: 600;
        }
    }

    p {
        font-size: 12px;
    }
}
