.top-applications-page {
    &__filter {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;
        gap: 10px;

        > * {
            width: calc(50% - 5px);
        }
    }
}
