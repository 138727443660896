@import 'variables.scss';

.not-found-page {
    min-height: 60vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &__title {
        text-align: center;
        font-size: 180px;
        font-weight: 700;
        color: $color-main;
    }

    &__subtitle {
        margin: 0 0 24px 0;
        text-align: center;
        font-size: 24px;
        font-weight: 800;
        font-weight: $color-main;
        text-transform: uppercase;
    }

    a {
        padding: 10px 16px;
        border-radius: $border-radius;
        background-color: $color-main;
        color: $color-white;
        font-size: 14px;
        font-weight: 600;
    }
}