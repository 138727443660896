@import 'variables.scss';

.signup-page {

    &__title {
        margin: 0 0 24px 0;
        font-size: 24px;
        font-weight: 600;
        color: $color-main;
    }

    &__form {
        display: flex;
        flex-direction: column;
        gap: 4px;
    }
}