@import "variables.scss";

.application__main-information {
    padding: 24px;
    margin-bottom: 32px;
    background-color: #ffffff;
    border-radius: 10px;
    box-shadow: $box-shadow;

    &__wrapper {
        &:not(:last-child) {
            margin: 0 0 12px 0;
        }
    }

    &__title {
        margin: 0 0 8px 0;
        font-size: 16px;
        font-weight: 600;
    }

    &__text {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        font-size: 14px;
        color: #181818;
    }

    &__links {
        display: flex;
        gap: 0 8px;

        a {
            padding: 8px 12px;
            margin: 12px 0 0 0;
            border-radius: $border-radius-large;
            background-color: $color-main;
            font-size: 12px;
            color: $color-white;
        }
    }
}
