.nominate {
    position: relative;
    padding: 95px 0 100px 0;
    margin: 0 0 100px 0;
    background-color: #354596;
}

@media (max-width: 767px) {
    .nominate {
        padding: 60px 0 60px 0;
        margin: 0 0 60px 0;
    }
}

.nominate__grid {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    height: 100%;
}

@media (max-width: 767px) {
    .nominate__grid {
        display: none;
    }
}

.nominate__content {
    position: relative;
    display: flex;
}

@media (max-width: 767px) {
    .nominate__content {
        flex-direction: column;
        gap: 60px;
    }
}

.nominate__arrow {
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
}

@media (max-width: 767px) {
    .nominate__arrow {
        display: none;
    }
}

.nominate__content p {
    width: 50%;
    padding: 0 !important;
    font-size: 20px;
    font-weight: 700;
    line-height: 140%;
    color: #ffffff;
}

@media (max-width: 767px) {
    .nominate__content p {
        width: 100%;
        font-size: 18px;
    }
}

.nominate__content p span {
    position: relative;
    text-transform: uppercase;
    z-index: 1;
}

.nominate__content p span::after {
    content: "";
    position: absolute;
    z-index: -1;
    width: calc(100% + 14px);
    height: 14px;
    top: 54%;
    left: -14px;
    background-image: url("../../../../../assets/img/home/nominate-span.svg");
}

.nominate__content div {
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

@media (max-width: 767px) {
    .nominate__content div {
        width: 100%;
    }
}

.nominate__content div a {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 15px 70px;
    border-radius: 40px;
    border: 2px solid #ffffff;
    background-color: #354596;
    font-size: 13px;
    font-weight: 700;
    line-height: 150%;
    color: #ffffff;
    text-transform: uppercase;
    cursor: pointer;
}
