.button-group {
    display: flex;
    align-items: center;
    gap: 0 8px;

    &[data-position='end'] {
        justify-content: flex-end;
    }

    &[data-position='start'] {
        justify-content: flex-start;
    }

    &[data-position='center'] {
        justify-content: center;
    }
}
