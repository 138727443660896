@import "variables.scss";

.assessor-welcome {
    min-height: 90vh;
    padding: 140px 0 140px 0;
    background-image: url("/assets/img/background.png");
    background-repeat: no-repeat;
    background-size: cover;
    color: $color-white;

    @media (max-width: $container-md) {
        & {
            padding: 60px 0 60px 0;
        }
    }

    &__title {
        margin: 0 0 24px 0;
        font-size: 42px;
        font-weight: 900;
        text-transform: uppercase;
        color: #ffae00;

        @media (max-width: $container-md) {
            font-size: 24px;
        }
    }

    p {
        margin: 0 0 12px 0;
        font-size: 14px;
        font-weight: 500;

        a {
            color: #ffae00;
            text-decoration: underline;
        }
    }

    &__button {
        display: inline-flex;
        padding: 10px 16px;
        margin: 20px 0 0 0;
        border-radius: $border-radius;
        background-color: #ffae00;
        font-size: 14px;
        font-weight: 600;
        color: $color-black;
        text-transform: uppercase;
    }
}

.assessor-welcome__wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 40px;

    @media (max-width: $container-md) {
        & {
            flex-direction: column;
        }
    }

    > div {
        width: 50%;

        @media (max-width: $container-md) {
            & {
                width: 100%;
            }
        }

        > div {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            @media (max-width: $container-md) {
                & {
                    justify-content: center;
                }
            }
        }
    }

    > div:last-child {
        display: flex;
        align-items: center;
        justify-content: center;

        img {
            width: 100%;

            @media (max-width: $container-md) {
                & {
                    max-width: 400px;
                }
            }
        }
    }
}
