@import "variables.scss";

.mobile-menu {
    display: none;

    @media (max-width: $container-md) {
        display: block;
    }

    &__wrapper {
        position: fixed;
        top: 0;
        left: 0;
        display: none;
        flex-direction: column;
        width: 100%;
        height: 100%;
        padding: 60px 30px 60px 30px;
        background-color: #ffffff;
        z-index: 999;

        &[data-open="true"] {
            display: flex;
            max-width: 100vh;
        }
    }

    &__open-button {
        display: flex;
        justify-content: center;
        align-items: center;

        img {
            width: 32px;
            height: 32px;
        }
    }

    &__close-button {
        position: absolute;
        top: 12px;
        right: 12px;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 32px;
        height: 32px;
        border-radius: 50%;
        background-color: $color-main-pale;

        img {
            width: 24px;
            height: 24px;
            filter: brightness(0.5);
        }
    }

    &__item {
        display: flex;
        flex-direction: column;
        gap: 8px;
        padding: 10px 10px 10px 10px;
        margin: 0 0 10px 0;
        border-radius: $border-radius;
        background-color: $color-main-pale;
        &__link {
            color: $color-main;
            font-weight: 600;
        }

        &__submenu {
            display: flex;
            flex-direction: column;
            gap: 8px;
            padding: 0 0 0 10px;
            a {
                color: $color-main;
            }
        }
    }
}
