.about {
    position: relative;
    min-height: 780px;
    padding: 70px 0 0 0;
    margin: 0 0 100px 0;

    @media (max-width: 1024px) {
        & {
            min-height: unset;
        }
    }

    @media (max-width: 767px) {
        & {
            margin: 0 0 60px 0;
            padding: 30px 0 0 0;
        }
    }

    &__title {
        position: relative;
        max-width: 632px;
        padding: 70px 0 0 130px;
        margin: 0 0 130px 0;
        font-size: 40px;
        font-weight: 700;
        line-height: 130%;
        color: #354596;
        text-transform: uppercase;

        @media (max-width: 1024px) {
            & {
                padding: 70px 0 0 40px;
                margin: 0 0 40px 0;
                font-size: 35px;
            }
        }

        @media (max-width: 767px) {
            & {
                padding: 0;
                margin: 0 0 35px 0;
                font-size: 32px;
            }
        }

        > br {
            display: none;

            @media (max-width: 400px) {
                & {
                    display: unset;
                }
            }
        }
    }

    &__divider {
        display: flex;
        align-items: flex-start;
        margin-bottom: 100px;

        @media (max-width: 767px) {
            & {
                flex-direction: column-reverse;
                margin: 0;
            }
        }

        > div {
            display: flex;
            flex-direction: column;
            justify-content: center;
            width: 50%;

            @media (max-width: 767px) {
                & {
                    width: 100%;
                }
            }

            &:first-child > img {
                position: absolute;
                top: 50%;
                left: 0;
                transform: translateY(-50%);
                width: calc(100vw / 2);
                max-width: 700px;
            }

            @media (max-width: 1200px) {
                & {
                    height: 100%;
                    object-fit: cover;
                    min-height: 380px;
                }
            }

            @media (max-width: 767px) {
                & {
                    position: static;
                    left: unset;
                    width: calc(100% + 40px);
                    height: 300px;
                    min-height: unset;
                    margin: 0 -20px 40px;
                }
            }
        }
    }

    &__illustration {
        display: block;

        @media (max-width: 767px) {
            & {
                display: none;
            }
        }

        &--mobile {
            display: none;

            @media (max-width: 767px) {
                & {
                    display: block;
                    width: 100%;
                }
            }
        }
    }

    &__text {
        max-width: 590px;
        padding: 0 0 0 120px;
        font-size: 16px;
        font-weight: 400;
        line-height: 150%;
        color: #041e42;

        @media (max-width: 1024px) {
            & {
                padding: 0 0 0 40px;
                margin: 0 0 40px 0;
            }
        }

        @media (max-width: 767px) {
            & {
                padding: 0;
                margin-bottom: 60px;
            }
        }

        &:first-child {
            margin-bottom: 25px;
        }

        > span {
            font-weight: 700;
            color: #5ca0f5;
        }
    }

    &__arrow {
        display: block;
        position: absolute;
        left: 50%;
        top: 94%;
        width: 134px;

        @media (max-width: 1330px) {
            & {
                left: 53%;
                top: 94%;
            }
        }

        @media (max-width: 1024px) {
            & {
                display: none;
            }
        }
    }
}
