@import "variables.scss";

.assessor-applications {
    &__evaluation-information {
        padding: 24px;
        margin-bottom: 32px;
        border-radius: $border-radius;
        background-color: $color-main;
        box-shadow: $box-shadow;
        color: #ffffff;
        font-size: 20px;
        font-weight: 600;
        text-align: center;

        > span {
            color: $color-third;
        }
    }
}
