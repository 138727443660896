@import "variables";

.modal-window {
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100vh;
    background-color: #e4e4e47d;
    backdrop-filter: blur(10px);
    overflow: hidden;
    z-index: 9999;

    &-wrapper {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        min-width: 300px;
        max-width: 500px;
        padding: 40px;
        border-radius: 12px;
        background-color: #ffffff;

        &-close-button {
            position: absolute;
            top: 16px;
            right: 16px;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 28px;
            height: 28px;
            border: 2px solid $color-main;
            border-radius: 50%;
            background-color: transparent;
            font-size: 14px;
            font-weight: 600;
            color: $color-main;
            cursor: pointer;
            transition: all 0.3s;

            &:hover {
                background-color: $color-main-pale;
            }
        }

        > h2 {
            margin: 0 0 24px 0;
            font-size: 24px;
            font-weight: 600;
            text-align: center;
            color: $color-main;
            text-transform: uppercase;
        }

        > p {
            margin: 0 0 32px 0;
            font-size: 16px;
            text-align: center;
        }
    }
}
