@import "variables.scss";

.application-page {
    &__section {
        display: grid;
        grid-template-columns: 3fr 1fr;
        gap: 20px;

        @media (max-width: $container-lg) {
            grid-template-columns: 1fr;
        }
    }
}
