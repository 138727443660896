@import "variables.scss";

.menu {
    display: flex;
    gap: 0 12px;

    @media(max-width: $container-md) {
        & {
            display: none;
        }
    }

    &__item {
        position: relative;
        display: block;


        &:hover {
            .menu__item__submenu {
                max-height: 1000px;
                transition: max-height 1s ease-in-out;
            }
        }

        &__link {
            padding: 8px 12px;
            border-radius: 25px;
            color: $color-black;
            font-size: 14px;

            &.active {
                background-color: $color-main-pale;
                color: $color-main;
                font-weight: 600;
            }
        }

        &__submenu {
            position: absolute;
            top: calc(100% + 7px);
            left: 0;
            display: flex;
            flex-direction: column;
            width: auto;
            min-width: 180px;
            max-height: 0px;
            padding: 0;
            border-radius: 8px;
            overflow: hidden;
            box-shadow: $box-shadow-submenu;
            background-color: $color-white;
            transition: max-height 0.5s cubic-bezier(0, 1, 0, 1);

            a {
                display: block;
                min-width: fit-content;
                padding: 8px 12px;
                font-size: 14px;
                color: $color-black;
                transition: background-color 0.3s;

                &:hover {
                    background-color: $color-main-pale;
                }

                &.active {
                    color: $color-main;
                }
            }
        }
    }
}
