@import "../../../variables";

.input {
    position: relative;
    width: 100%;

    &-focused label {
        border: 1px solid $color-main;
    }

    &-disabled label {
        background-color: #f1f1f1;
        cursor: not-allowed;
    }

    &[data-validation="false"] .input-label {
        color: red;
        border: 1px solid red;
    }

    &-label {
        display: block;
        padding: 8px 12px 0 12px;
        font-size: 14px;
        font-weight: 600;
        border: 1px solid #e5e7eb;
        border-radius: $border-radius;
        background-color: #ffffff;
        color: $color-main;
        transition: all 0.3s;
        user-select: none;
        cursor: pointer;

        &-required {
            color: #ff0000;
        }
    }

    input {
        width: 100%;
        height: 32px;
        padding: 0;
        margin: 0 0 4px 0;
        border: 0;
        background-color: #ffffff;
        font-size: 14px;
    }

    input:focus {
        outline: none;
    }

    input:disabled {
        background-color: #f1f1f1;
        cursor: not-allowed;
    }

    &-validation-text {
        font-size: 12px;
        color: #ff0000;
    }
}
