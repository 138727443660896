@import "variables.scss";

.users-table {
    border-collapse: separate;
    border-spacing: 0 8px;
    width: 100%;
    font-size: 12px;
    color: $color-black;

    thead tr th {
        background-color: $color-main;
        color: $color-white;
        font-weight: 700;
    }

    tr {
        > :is(th, td):nth-child(1) {
            width: 4%;
        }

        > :is(th, td):nth-child(2) {
            width: 4%;
        }

        > :is(th, td):nth-child(3) {
            width: 20%;
        }

        > :is(th, td):nth-child(4) {
            width: 10%;
        }
    }

    th,
    td {
        max-width: 100px;
        padding: 16px 12px;
        border-color: #ebebeb;
        border-style: solid;
        border-width: 1px 0 1px 0;
        vertical-align: middle;
        text-overflow: ellipsis;

        span {
            display: inline-flex;
            flex-wrap: wrap;
            gap: 4px;

            a {
                display: inline-flex;
                justify-content: center;
                align-content: center;
                padding: 8px 4px;
                border-radius: $border-radius;
                background-color: $color-main;
                color: $color-white;
            }
        }
    }

    :is(th, td):first-child {
        border-width: 1px 0 1px 1px;
        border-radius: $border-radius 0 0 $border-radius;
    }

    :is(th, td):last-child {
        border-width: 1px 1px 1px 0;
        border-radius: 0 $border-radius $border-radius 0;
    }
}
