@import "variables";

.assessor-application__filter {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    gap: 8px;
    margin: 0 0 20px 0;
    font-size: 14px;

    &-control {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 8px;

        button {
            padding: 8px 16px;
            border-radius: $border-radius;
            background-color: $color-main-pale;
            font-size: 14px;
            color: $color-white;

            &[data-active="true"] {
                background-color: $color-main;
            }
        }
    }
}
