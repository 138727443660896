@import "variables.scss";

.files-list {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
}

.files-list > div {
    margin: 10px 0;
}

.files {
    padding: 24px;
    border-radius: 10px;
    background: #ffffff;
    box-shadow: 4px 4px 8px #f8f8f8;
    color: #181818;

    h3 {
        margin-bottom: 20px;
        font-size: 16px;
        font-weight: 600;
    }
}

.files-empty {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    min-height: 64px;
    margin-bottom: 8px;
    border-radius: 10px;
    color: #181818;
    font-size: 14px;
}

.files-url {
    width: 100%;
    box-shadow: 4px 4px 8px #f8f8f8;
}

.files-url > a {
    display: block;
    padding: 12px 8px;
    border-radius: $border-radius;
    background-color: $color-main;
    font-size: 14px;
    color: $color-white;
    text-decoration: none;
    text-align: center;
}
