@import 'variables.scss';

.application__header {
    &-title {
        margin: 0 0 16px 0;
        font-weight: 600;
        font-size: 36px;
        color: $color-additional;
    }

    &-subtitle {
        margin: 0 0 44px 0;
        font-size: 20px;
        font-weight: 400;
        color: #c7c7c7;
    }
}
