.application-breadcrumbs {
    margin: 0 0 18px 0;

    a {
        display: flex;
        align-items: center;
        gap: 0 8px;
        color: rgba(0, 0, 0, 0.2);
        font-weight: 600;

        img {
            opacity: 0.2;
        }
    }
}
