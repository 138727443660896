@import "variables.scss";

.application-page__main-content {
    display: grid;
    grid-template-columns: 3fr 1fr;
    gap: 20px;
    margin: 0 0 20px 0;

    @media(max-width: $container-lg) {
        grid-template-columns: 1fr;
    }
}
.application-page__evaluating-form {
    display: grid;
    grid-template-columns: 3fr 1fr;
    gap: 20px;

    @media(max-width: $container-lg) {
        grid-template-columns: 1fr;
    }
}

.application__data {
    padding: 24px;
    margin-bottom: 32px;
    border-radius: 10px;
    background-color: #ffffff;
    box-shadow: 4px 4px 8px #f8f8f8;

    &:last-child {
        margin:  0 0 0 0;
    }
}

.application__assessment-title {
    margin: 0 0 24px 0;
    font-size: 24px;
    font-weight: 700;
    color: $color-main;
}
