@import "variables.scss";

.page-table-container {
    @media (max-width: $container-md) {
        overflow-x: scroll;
    }
}

.page-table {
    border-collapse: initial;
    border-spacing: 0 8px;
    width: 100%;
    font-size: 12px;
    color: $color-black;

    thead > tr > th {
        background-color: $color-main;
        color: $color-white;
        font-weight: 700;
    }

    th,
    td {
        padding: 16px 12px;
        border-color: $color-border;
        border-style: solid;
        border-width: 1px 0 1px 0;
        vertical-align: middle;
    }

    :is(th, td):first-child {
        border-width: 1px 0 1px 1px;
        border-radius: $border-radius 0 0 $border-radius;
    }

    :is(th, td):last-child {
        border-width: 1px 1px 1px 0;
        border-radius: 0 $border-radius $border-radius 0;
    }
}
