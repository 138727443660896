@import "variables.scss";

.login-page {
    display: flex;
    align-items: center;
    justify-content: center;

    .login {
        display: flex;
        flex-direction: column;
        gap: 20px;
        min-width: 400px;
        max-width: 40%;
        padding: 40px;
        margin: 20px 0 0 0;
        border-radius: $border-radius;
        background-color: $color-bg;

        &__title {
            text-align: center;
        }

        &__logo {
            display: flex;
            justify-content: center;

            img {
                width: 100%;
                max-width: 300px;
            }
        }

        &__form {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 8px;

            form {
                display: flex;
                flex-direction: column;
                align-items: center;
                gap: 8px;
            }
        }

        &__message {
            padding: 10px;
            border-radius: 4px;
            background-color: $color-alert;
            color: $color-white;
        }
    }
}
