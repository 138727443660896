@import 'variables.scss';

.not-validated-applications-page {
    &__reload-button {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 6px;
        border-radius: 50%;
        transition: all .3s;

       &:hover {
        background-color: $color-main-pale;
       }
    }
}