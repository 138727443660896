.nomination-form {
    width: 100%;
    margin: 0 0 20px 0;
    display: flex;
    flex-direction: column;
    gap: 20px;
    
    &__row {
        display: flex;
        gap: 20px;

        > div {
            flex: 0 0 calc(50% - 10px);
        }
    }
}
