@import "variables.scss";

.profile {
    &__wrapper {
        display: flex;
        padding: 10px 10px 10px 10px;
        gap: 20px 50px;

        @media (max-width: $container-md) {
            flex-direction: column;
        }
    }

    &__icon-information {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 20px;

        p {
            text-align: center;
        }
    }

    &__icon {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 140px;
        height: 140px;
        border-radius: 50%;
        background-color: $color-main-pale;
        font-size: 42px;
        font-weight: 600;
    }

    &__information {
        flex: 1 1 auto;
        display: flex;
        flex-direction: column;
        gap: 6px;

        &-username {
            margin: 0 0 8px 0;
            font-size: 24px;
            font-weight: 700;
            text-transform: uppercase;
        }

        &__roles {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            gap: 10px;

            span {
                font-weight: 600;
                color: $color-main;
            }

            div {
                padding: 8px 12px;
                border-radius: 20px;
                background-color: $color-main-pale;
                font-size: 14px;
            }
        }
    }
}
