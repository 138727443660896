.selection__title {
    font-weight: 700;
    font-size: 40px;
    line-height: 130%;
    color: #354596;
    text-align: center;
    margin-bottom: 60px;
    text-transform: uppercase;
}

@media (max-width: 767px) {
    .selection__title {
        max-width: 360px;
        margin: 0 auto 45px;
        font-size: 28px;
    }
}

.selection__items {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    gap: 50px 250px;
    margin-bottom: 100px;
    background-image: url("https://globalteacherprize.org.ua/wp-content/uploads/2024/03/form2024-flow-arrow.png");
    background-repeat: no-repeat;
    background-position: 44% bottom;
    background-size: 180px;
}

@media (max-width: 1024px) {
    .selection__items {
        gap: 50px 120px;
        background-image: unset;
    }
}

@media (max-width: 920px) {
    .selection__items {
        flex-direction: column;
        justify-content: center;
        align-content: center;
        flex-wrap: wrap;
    }
}

@media (max-width: 767px) {
    .selection__items {
        gap: 45px 0;
        margin-bottom: 5px;
    }
}

.selection__items > div {
    display: flex;
    align-items: center;
    flex-direction: column;
    flex-wrap: nowrap;
    gap: 50px 0;
}

.selection__items > div > div {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    flex-wrap: nowrap;
    gap: 0 30px;
}

@media (max-width: 920px) {
    .selection__items > div > div {
        align-items: center;
    }
}

.selection__items > div > div > img {
    height: 92px;
    margin-bottom: 32px;
}

@media (max-width: 920px) {
    .selection__items > div > div > img {
        height: 72px;
        margin: 0;
    }
}

.selection__items > div > div > p {
    padding: unset;
    text-align: left;
    font-size: 16px;
    line-height: 150%;
    color: #000000;
}

.selection__items > div > div > p > span {
    display: block;
    padding-bottom: 12px;
    font-size: 28px;
    font-weight: 700;
    color: #041e42;
}

.selection__note {
    max-width: 772px;
    margin: 0 auto;
    font-size: 20px;
    font-weight: 700;
    line-height: 140%;
    color: #354596;
}

@media (max-width: 920px) {
    .selection__note {
        margin: 60px 0 60px 0;
        font-size: 18px;
    }
}
