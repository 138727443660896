.quote {
    margin: 0 0 100px 0;
}

@media (max-width: 767px) {
    .quote {
        margin: 0 0 60px 0;
    }
}

.quote__icon {
    width: 90px;
    margin-left: 116px;
}

@media (max-width: 1200px) {
    .quote__icon {
        margin-left: 60px;
    }
}

@media (max-width: 860px) {
    .quote__icon {
        margin-left: 20px;
    }
}

@media (max-width: 767px) {
    .quote__icon {
        width: 80px;
        margin-left: 0;
    }
}

.quote__text {
    width: 655px;
    margin-left: 202px;
    font-weight: 700;
    font-size: 20px;
    line-height: 1.4;
    color: #354596;
}

@media (max-width: 1200px) {
    .quote__text {
        margin-left: 150px;
    }
}

@media (max-width: 860px) {
    .quote__text {
        width: auto;
        margin-left: 110px;
    }
}

@media (max-width: 767px) {
    .quote__text {
        width: auto;
        margin-left: 0;
        font-size: 18px;
    }
}

.quote__person {
    width: 328px;
    margin-left: 706px;
    font-size: 16px;
    line-height: 1.5;
    color: #041e42;
}

@media (max-width: 1200px) {
    .quote__person {
        margin-left: 500px;
    }
}

@media (max-width: 860px) {
    .quote__person {
        margin-left: 400px;
    }
}

@media (max-width: 767px) {
    .quote__person {
        width: auto;
        margin-left: 132px;
    }
}
