.error {
    display: flex;
    align-items: center;
    min-height: 60vh;
}

.error h1 {
    margin: 0 0 32px 0;
}

.error h2 {
    margin: 0 0 14px 0;
    font-size: 20px;
}