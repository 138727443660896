@import "variables";

.application-nav {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    padding: 20px 10px;
    margin-bottom: 24px;
    border-radius: $border-radius;
    background-color: $color-bg;
    box-shadow: $box-shadow;

    @media(max-width: $container-sm) {
        & {
           flex-wrap: wrap; 

           > a {
            flex: 0 0 calc(50% - 40px);
           }
        }
    }

    a {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 8px 20px;
        border: 1px solid $color-additional-pale;
        border-radius: calc($border-radius * 2);
        background-color: $color-bg;
        font-size: 14px;
        font-weight: 500;
        color: $color-black;
        text-decoration: none;

        &.active {
            background-color: $color-bg;
            border: 1px solid $color-main-pale;
        }
    }
}