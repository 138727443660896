@import "variables.scss";

.application-assessors {
    &__category-name {
        margin: 0 0 12px 0;
        font-size: 18px;
        font-weight: 600;
        color: $color-main;
    }

    &__category-assessors {
        display: flex;
        flex-direction: column;
        gap: 8px;
        margin: 0 0 12px 0;
    }

    &__category-assessor {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 8px;
        padding: 8px 12px;
        border: 1px solid $color-border;
        border-radius: $border-radius;
        font-size: 14px;
    }
}
