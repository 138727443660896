@import 'variables.scss';

.page-note {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 100px;
    padding: 10px;
    border: 1px solid $color-border;
    border-radius: $border-radius;
    background-color: $color-note-background;
    font-size: 14px;
    font-weight: 400;
    color: $color-main;
}