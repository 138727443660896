@import "variables.scss";

.comments {
    display: flex;
    flex-direction: column-reverse;
    max-height: 400px;
    padding: 20px 20px 20px 20px;
    margin: 12px 0 12px 0;
    border: 1px solid $color-border;
    border-radius: $border-radius;
    background-color: $color-white;
    overflow-y: scroll;

    &::-webkit-scrollbar {
        width: 10px;
    }

    &::-webkit-scrollbar-track {
        background-color: $color-border;
    }

    &::-webkit-scrollbar-thumb {
        background-color: $color-main;
    }
}

.comment {
    display: flex;
    align-items: flex-end;
    margin-bottom: 12px;

    &[data-current-user="true"] {
        align-self: flex-end;
    }
}

.comment__icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    margin: 0 8px 0 0;
    background-color: #b4d6ff;
    border-radius: 50%;
    font-weight: 700;

    &[data-current-user="true"] {
        order: 1;
        margin: 0 0 0 8px;
    }
}

.comment__block {
    position: relative;
    width: fit-content;
    display: flex;
    padding: 20px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 16px;
    border: 1px solid #e5e7eb;
    border-radius: $border-radius;
    background-color: #ffffff;
    box-shadow: $box-shadow;
    max-width: 100%;

    h3 {
        color: #354596;
        font-size: 16px;
        font-weight: 600;

        > span {
            padding-left: 8px;
            color: #ababab;
            font-size: 14px;
            font-weight: 400;
        }

        > p {
            max-width: 100%;
            overflow: hidden;
        }
    }
}
