@import "variables.scss";

.applications-table-wrapper h2 {
    margin: 24px 0;
    font-size: 32px;
}

.applications-table-wrapper {
    @media (max-width: $container-md) {
        overflow-x: scroll;
    }

    table {
        border-collapse: initial;
        border-spacing: 0 8px;
        width: 100%;
        font-size: 12px;
        color: $color-black;

        thead tr th {
            background-color: $color-main;
            color: $color-white;
            font-weight: 700;
        }

        thead {
            :is(th, td):nth-child(1) {
                width: 2%;
            }

            :is(th, td):nth-child(2) {
                width: 2%;
            }
        }

        tr {
        }

        th,
        td {
            padding: 16px 12px;
            border-color: #ebebeb;
            border-style: solid;
            border-width: 1px 0 1px 0;
        }

        :is(th, td):first-child {
            border-width: 1px 0 1px 1px;
            border-radius: $border-radius 0 0 $border-radius;
        }

        :is(th, td):last-child {
            border-width: 1px 1px 1px 0;
            border-radius: 0 $border-radius $border-radius 0;
        }
    }
}

.applications-table-wrapper table tbody tr {
    border-bottom: 1px solid #fafafa;
}

.applications-table-wrapper table tr > * {
    vertical-align: middle;
}

.applications-table-wrapper table[data-story-type="about-teacher-stories"] tr > *:is(:nth-child(2), :nth-child(6), :nth-child(7)) {
    text-align: center;
}

.applications-table-wrapper
    table[data-story-type="teacher-stories"]
    tr
    > *:is(:nth-child(2), :nth-child(3), :nth-child(6), :nth-child(7), :nth-child(8), :nth-child(9), :nth-child(10)) {
    text-align: center;
}

.applications-table-wrapper table tr > * > a {
    font-size: 14px;
}

.applications-table {
    &__assessors__td {
        > span {
            display: flex;
            flex-direction: column;
            flex-wrap: wrap;
            gap: 4px;

            > a {
                display: inline-flex;
                width: fit-content;
                padding: 2px 6px;
                border-radius: 12px;
                background-color: $color-main-pale;
                font-size: 12px;
                color: $color-black;
                transition: all 0.3s;

                &:hover {
                    background-color: $color-main-pale-hover;
                }
            }
        }
    }

    &__points {
        span {
            display: inline-flex;
            align-items: center;
        }
    }
}
