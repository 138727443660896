@import "variables.scss";

.nav {
    &__profile {
        position: relative;
        display: flex;
        align-items: center;
        gap: 12px;
        min-width: 140px;
        padding: 4px 8px;
        border: 1px solid $color-border;
        border-radius: $border-radius;
        cursor: pointer;
        transition: all 0.3s;

        @media (max-width: $container-md) {
            min-width: unset;
            border: unset;
        }

        &:active {
            background-color: $color-main-pale;
        }
    }

    &__icon {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 40px;
        height: 40px;
        background-color: $color-main-pale;
        border-radius: 50%;
        font-size: 16px;
        font-weight: 700;
    }

    &__username {
        font-size: 14px;
        font-weight: 500;
        text-transform: capitalize;

        @media (max-width: $container-md) {
            display: none;
        }
    }

    &__menu {
        position: absolute;
        top: calc(100% + 1px);
        right: 0%;
        display: block;
        min-width: 140px;
        width: 100%;
        max-height: 0;
        padding: 0;
        border-radius: 8px;
        overflow: hidden;
        list-style: none;
        background-color: #ffffff;
        font-size: 14px;
        transition: max-height 0.5s cubic-bezier(0, 1, 0, 1);

        &[data-open="true"] {
            max-height: 1000px;
            transition: max-height 1s ease-in-out;
        }

        li {
            padding: 10px;
            transition: all 0.3s;
            color: $color-black;

            &:hover {
                background-color: $color-main-pale;
            }

            > * {
                display: inline-block;
                width: 100%;
                height: 100%;
                color: black;

                &:hover {
                    color: $color-main;
                }
            }
        }
    }
}
