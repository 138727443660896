$xsm: 300px;
$sm: 576px;
$md: 768px;
$lg: 992px;
$xl: 1200px;
$xxl: 1400px;

$container-sm: 540px;
$container-md: 720px;
$container-lg: 960px;
$container-xl: 1140px;
$container-xxl: 1320px;

$color-main: #354596;
$color-main-saturated: #122273;
$color-main-pale: #d6ddff;
$color-main-pale-hover: #c5cefd;

$color-additional: #44aae0;
$color-additional-pale: #f7fcff;

$color-third: #ffae00;
$color-third-pale: #ffd06b;

$color-black: #181818;
$color-white: #ffffff;
$color-grey: #cecece;

$color-bg: #ffffff;

$color-alert: #f95959;

$color-border: #e5e7eb;
$color-note-background: #f8fbff;

$box-shadow: 4px 4px 8px #f8f8f8;
$box-shadow-submenu: 2px 2px 4px rgba(0, 0, 0, 0.1);
$border-radius: 10px;
$border-radius-large: 25px;

$label-padding: 10px 18px;
