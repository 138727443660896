.hero {
    padding: 0;
    position: relative;
    z-index: 0;

    &__header {
        height: 620px;
        background-image: url("../../../../../assets/img/home/hero-background.png");
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;

        @media (max-width: 920px) {
            & {
                height: 420px;
            }
        }

        @media (max-width: 600px) {
            & {
                background-image: url("../../../../../assets/img/home/hero-background-mobile.png");
                height: 680px;
                background-position: center center;
            }
        }

        @media (max-width: 400px) {
            & {
                height: 570px;
            }
        }
    }
}

.about__action {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    width: 100%;
    height: 100%;
    padding: 0 0 36px;
}

@media (max-width: 600px) {
    .about__action {
        flex-direction: column;
        justify-content: flex-end;
        align-items: center;
        gap: 12px;
        padding: 0 0 12px;
    }
}

.about__action-button {
    padding: 15px 70px;
    margin: 0;
    border: 2px solid #354596;
    border-radius: 40px;
    background-color: transparent;
    text-align: center;
    font-size: 13px;
    font-weight: 700;
    line-height: 150%;
    color: #354596;
    text-transform: uppercase;
}

@media (max-width: 767px) {
    .about__action-button {
        width: auto;
        font-size: 13px;
    }
}

@media (max-width: 600px) {
    .about__action-button {
        min-width: 80%;
    }
}

@media (max-width: 500px) {
    .about__action-button {
        min-width: 90%;
    }
}

.about__action-button:first-child {
    margin-right: 10%;
}

@media (max-width: 600px) {
    .about__action-button:first-child {
        margin-right: 0;
    }
}

.about__action-button:focus,
.about__action-button:hover {
    -webkit-box-shadow: unset;
    box-shadow: unset;
}
