@import "variables";

.file-input {
    &__input {
        display: none;
    }

    &__container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 10px;
        padding: 10px 10px 10px 10px;
        border: 1px solid $color-border;
        border-radius: $border-radius;

        @media (max-width: $container-md) {
            flex-direction: column;
            gap: 20px;
        }
    }

    &__file-name {
        display: inline-block;
        word-break: break-word;
        font-size: 14px;
        color: $color-main;

        @media (max-width: $container-md) {
            text-align: center;
        }
    }

    &__buttons {
        flex: 0 0 300px;
        display: flex;
        align-items: center;
        gap: 8px;

        @media (max-width: $container-md) {
            flex: 1 1 auto;
        }

        &__label {
            padding: 8px 20px;
            border: 1px solid #354596;
            border-radius: 8px;
            background-color: #354596;
            font-size: 14px;
            color: #ffffff;
            cursor: pointer;
            overflow: hidden;
            transition: all 0.3s;

            &:hover {
                background-color: $color-main-saturated;
            }

            &:active {
                background-color: $color-main-saturated;
            }
        }
    }
}
