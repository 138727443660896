@import 'variables.scss';

.applications-sorting {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 10px 8px;

    button {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        gap: 4px;
        padding: $label-padding;
        border-radius: $border-radius-large;
        background-color: $color-main-pale;
        font-size: 14px;

        &[data-active="true"] {
            background-color: $color-main;
            color: $color-white;
        }

        .arrow {
            filter: brightness(0) invert(1);
        }

        .arrow-rotated {
            filter: brightness(0) invert(1);
            transform: rotate(180deg);
        }

        
    }
}