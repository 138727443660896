@import "../../../variables.scss";

.application__assessment-criterion {
    &:not(:last-child) {
        margin-bottom: 12px;
    }
}

.application__assessment-criterion h3 {
    margin: 0;
    font-size: 20px;
    font-weight: 400;
}

.application__assessment-criterion h3 > img {
    transition: transform 0.5s;
}

.criterions-arrow {
    transform: rotate(0);
    transition: all 0.3s;
}

.criterions-arrow-rotate {
    transform: rotate(-180deg);
    transition: all 0.3s;
}

.application__assessment-criterion > div:first-child {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 12px 18px 12px 12px;
    border-radius: 6px;
    transition: background-color 0.3s;
    cursor: pointer;
    user-select: none;
}

.application__assessment-criterion > div:first-child:hover {
    background-color: $color-main-pale;
}

.application__assessment-criterion > div:first-child > div {
    display: flex;
    align-items: center;
    gap: 12px;
    color: $color-main;
    font-size: 14px;
    font-weight: 600;

    > div:first-child {
        width: 60px;
        padding: 4px;
        border: 1px solid $color-main;
        border-radius: 6px;
        text-align: center;
        font-size: 24px;
        font-weight: 700;
    }
}

.application__assessment-criterion > div:last-child {
    overflow: hidden;

    table {
        margin: 12px 0;
    }
}

.application__assessment-criterion__content {
    height: auto;
    max-height: 1000vh;
    transition: max-height 1s ease-in-out;

    &_hidden {
        max-height: 0;
        transition: max-height 1s cubic-bezier(0, 1, 0, 1);
    }

    table {
        width: 100%;
        border-collapse: separate;
        border-spacing: 0 8px;
        font-size: 14px;

        :is(td, th) {
            padding: 8px;
            border: 1px solid $color-main-pale;
            border-radius: 4px;
        }

        td:last-child {
            text-align: center;
        }
    }
}
