@import 'variables.scss';

.toggle-switch {
    position: relative;
    display: inline-block;
    width: 45px;
    height: 25px;
}
.toggle-switch input[type='checkbox'] {
    display: none;
}
.toggle-switch .switch {
    position: absolute;
    cursor: pointer;
    background-color: #efefef;
    border-radius: 25px;
    border: 1px solid $color-border;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transition: background-color 0.2s ease;
}
.toggle-switch .switch::before {
    position: absolute;
    content: '';
    left: 2px;
    top: 1.5px;
    width: 21px;
    height: 21px;
    background-color: #aaa;
    border-radius: 50%;
    transition: transform 0.3s ease;
}
.toggle-switch input[type='checkbox']:checked + .switch::before {
    transform: translateX(19px);
    background-color: $color-main;
}
.toggle-switch input[type='checkbox']:checked + .switch {
    background-color: $color-border;
}
