.navbar {
    height: 70px;
    background-color: #ffffff;
    border-bottom: 1px solid #f1f1f1;

    &__left {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 10px;
    }
}

.navbar-logo {
    height: 38px;
}

.user-info {
    display: flex;
    align-items: center;
    margin-right: 20px;
    color: #000;
    font-weight: bold;
    text-decoration: none;
}

.user-info > img {
    width: 40px;
    height: auto;
    margin: 0 10px;
}

.navbar-nav .nav-link.active,
.navbar-nav .nav-link.show {
    padding: 8px;
    color: #5ca0f5 !important;
    border-bottom: 1px solid #5ca0f5;
}
