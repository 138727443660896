.participation {
    margin: 0 0 100px 0;
}

@media (max-width: 767px) {
    .participation {
        margin: 0 0 60px 0;
    }
}

.participation__title {
    margin: 0 0 70px 0;
    text-align: center;
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: 130%;
    color: #354596;
    text-transform: uppercase;
}

@media (max-width: 1024px) {
    .participation__title {
        margin: 0 auto 70px auto;

        max-width: 560px;
    }
}

@media (max-width: 767px) {
    .participation__title {
        margin: 0 auto 45px auto;
        font-size: 32px;
    }
}

.participation__list {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    justify-items: center;
}

@media (max-width: 1024px) {
    .participation__list {
        grid-template-columns: repeat(2, 1fr);
        gap: 45px;
    }
}

@media (max-width: 767px) {
    .participation__list {
        grid-template-columns: 1fr;
    }
}

.participation__list div {
    width: 270px;
}

@media (max-width: 1024px) {
    .participation__list div {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
    }
}

.participation__list div img {
    width: 200px;
    height: 150px;
    margin: 0 0 30px 0;
    font-size: 26px;
    font-weight: 700;
    line-height: 110%;
    color: #041e42;
}

@media (max-width: 767px) {
    .participation__list div img {
        width: 150px;
        height: auto;
        margin: 0 0 20px 0;
    }
}

.participation__list div h4 {
    width: 100%;
    margin: 0 0 6px 0;
    font-size: 26px;
    font-weight: 700;
    line-height: 110%;
    color: #041e42;
}

@media (max-width: 767px) {
    .participation__list div h4 {
        font-size: 23px;
        margin: 0 0 8px 0;
    }
}

.participation__list div p {
    width: 228px;
    padding: 0;
    font-size: 16px;
    font-weight: 400;
    line-height: 150%;
    color: #041e42;
}
