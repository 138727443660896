@import "variables.scss";

.tabs-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.tab-buttons {
    position: relative;
    display: flex;
    padding: 10px 10px 10px 10px;
    margin: 0 0 20px 0;
    border-radius: 30px;
    width: 100%;
    background-color: #ffffff;
}

.tab-buttons button {
    flex: 1 1 50%;
    padding: 20px 20px;
    border: none;
    border-radius: 30px;
    cursor: pointer;
    font-size: 16px;
    font-weight: 600;
    transition: all 0.3s;
    z-index: 3;
    background-color: transparent;
    color: $color-main;

    &:hover {
        background-color: $color-main-pale;
    }

    &.active {
        background-color: transparent;
        color: white;
        transition: none;
    }

    > span {
        z-index: 1;
    }
}

.tab-slider {
    position: absolute;
    left: 10px;
    top: 10px;
    bottom: 10px;
    border-radius: 30px;
    background-color: $color-main;
    width: calc(50% - 14px);
    z-index: 2;
    transition: transform 300ms ease-out, -webkit-transform 300ms ease-out;
}

.tab-slider-right {
    transform: translateX(calc(100% + 7px));
}

.tab-content {
    width: 100%;
}
